import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios';
import { HOST } from 'src/constants';
import { useHistory } from 'react-router-dom'; 

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);


  const login = async (email, password) => {
    let params = {
      email: email,
      password: password
    }

    try {
      let response = await axios.post(`${HOST}/login`, params);
      const { access_token } = response.data;
      console.log(access_token)
      setSession(access_token);
      const decoded = jwtDecode(access_token);
      const response_user = await axios.get(`${HOST}/users/${response.data.user_id}`);
          let user = {
            user_id: response_user.data.id,
            avatar: response_user.data.gravatar.medium,
            avatar_small: response_user.data.gravatar.small,
            email: response_user.data.email,
            name: response_user.data.first_name + " " + response_user.data.last_name,
            roles: decoded.roles[0]
          }
      console.log("user",user)
      //setSession(user);
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    } catch (err) {
      throw new Error(`Incorrect email or password`);
    }


  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };
  const history = useHistory();
  const register = async (email, name, password) => {
   
    let userPayload = {
      "account": {
        "address1": "",
        "address2": "",
        "city": "",
        "country": "",
        "name": name,
        "region": "",
        "timezone": "America/Central",
        "zipcode": ""
      },
      "user": {
        "email": email,
        "first_name": name,
        "last_name": "",
        "password": password,
        "password_confirm": password
      }
    }
    console.log(userPayload)
   
    try{
      const response = await axios.post(`${HOST}/signup`, userPayload);
      userPayload = response.data;
    } catch (err) {
      throw new Error(`Registration failed`);
    }
    //window.localStorage.setItem('accessToken', accessToken);
    history.push('/login');
    dispatch({
      type: 'LOGOUT',
      payload: {
        userPayload
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const decoded = jwtDecode(accessToken);
        console.log("Init useeffect")
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          
          console.log("decoded",decoded)
          const response = await axios.get(`${HOST}/users/${decoded.sub}`);
          let user = {
            user_id: response.data.id,
            avatar: response.data.gravatar.medium,
            avatar_small: response.data.gravatar.small,
            email: response.data.email,
            group_id: response.data.group_id,
            name: response.data.first_name + " " + response.data.last_name,
            roles: decoded.roles[0]
          }
          console.log("user init", user)
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;