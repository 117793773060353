import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import LoadingScreen from "src/components/LoadingScreen";
import DashboardLayout from "src/layouts/DashboardLayout";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";

import MainLayout from "src/layouts/MainLayout";
import HomeView from "src/views/home/HomeView";
// import PricingView from "src/views/home/PricingView"
// import LanguageView from 'src/views/language/LanguageView'
// import ProductsView from 'src/views/shop/Products'
// import CartView from 'src/views/shop/Cart'
// import ProductDetailsView from 'src/views/shop/ProductDetails'
// import MapDetailsView from 'src/views/Map'

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/",
    component: HomeView,
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFoundView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/forgot-password",
    component: lazy(() => import("src/views/auth/ForgotPasswordView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/change-password/:code",
    component: lazy(() => import("src/views/auth/ChangePasswordView")),
  },
  {
    exact: true,
    path: "/login-unprotected",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/register",
    component: lazy(() => import("src/views/auth/RegisterView")),
  },
  {
    exact: true,
    path: "/register-unprotected",
    component: lazy(() => import("src/views/auth/RegisterView")),
  },
  {
    exact: true,
    path: "/chatbot",
    component: lazy(() => import("src/views/chatbot/ChatbotView")),
  },
  {
    path: "/app",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/app/management/users/:userModifyID",
        component: lazy(() => import("src/views/users/UsersModify")),
      },
      {
        exact: true,
        path: "/app/account",
        component: () => <Redirect to="/app/account/general" />,
      },
      {
        exact: true,
        path: "/app/account/:page",
        component: lazy(() => import("src/views/account/AccountView")),
      },
      {
        exact: true,
        path: "/app/calendar",
        component: lazy(() => import("src/views/calendar/CalendarView")),
      },
      {
        exact: true,
        path: ["/app/chat/new", "/app/chat/:threadKey"],
        component: lazy(() => import("src/views/chat/ChatView")),
      },
      {
        exact: true,
        path: "/app/chat",
        component: () => <Redirect to="/app/chat/new" />,
      },
      {
        exact: true,
        path: "/app/extra/charts/apex",
        component: lazy(() => import("src/views/extra/charts/ApexChartsView")),
      },
      {
        exact: true,
        path: "/app/extra/forms/formik",
        component: lazy(() => import("src/views/extra/forms/FormikView")),
      },
      {
        exact: true,
        path: "/app/extra/forms/redux",
        component: lazy(() => import("src/views/extra/forms/ReduxFormView")),
      },
      {
        exact: true,
        path: "/app/extra/editors/draft-js",
        component: lazy(() =>
          import("src/views/extra/editors/DraftEditorView")
        ),
      },
      {
        exact: true,
        path: "/app/extra/editors/quill",
        component: lazy(() =>
          import("src/views/extra/editors/QuillEditorView")
        ),
      },
      {
        exact: true,
        path: "/app/kanban",
        component: lazy(() => import("src/views/kanban/KanbanView")),
      },
      {
        exact: true,
        path: [
          "/app/mail/label/:customLabel/:mailId?",
          "/app/mail/:systemLabel/:mailId?",
        ],
        component: lazy(() => import("src/views/mail/MailView")),
      },
      {
        exact: true,
        path: "/app/mail",
        component: () => <Redirect to="/app/mail/all" />,
      },
      {
        exact: true,
        path: "/app/chatbot/:chatId",
        component: lazy(() => import("src/views/chatbot/ChatbotView/Designer")),
      },
      {
        exact: true,
        path: "/app/chatbot",
        component: lazy(() => import("src/views/chatbot/ChatbotView")),
      },
      {
        exact: true,
        path: "/app/management/customers",
        component: lazy(() => import("src/views/customer/CustomerListView")),
      },
      {
        exact: true,
        path: "/app/management/customers/:customerId",
        component: lazy(() => import("src/views/customer/CustomerDetailsView")),
      },
      {
        exact: true,
        path: "/app/management/customers/:customerId/edit",
        component: lazy(() => import("src/views/customer/CustomerEditView")),
      },
      {
        exact: true,
        path: "/app/management/invoices",
        component: lazy(() => import("src/views/invoice/InvoiceListView")),
      },
      {
        exact: true,
        path: "/app/management/invoices/:invoiceId",
        component: lazy(() => import("src/views/invoice/InvoiceDetailsView")),
      },
      {
        exact: true,
        path: "/app/management/orders",
        component: lazy(() => import("src/views/order/OrderListView")),
      },
      {
        exact: true,
        path: "/app/management/orders/:orderId",
        component: lazy(() => import("src/views/order/OrderDetailsView")),
      },
      {
        exact: true,
        path: "/app/management/products",
        component: lazy(() => import("src/views/product/ProductListView")),
      },
      {
        exact: true,
        path: "/app/management/products/create",
        component: lazy(() => import("src/views/product/ProductCreateView")),
      },
      {
        exact: true,
        path: "/app/management",
        component: () => <Redirect to="/app/management/customers" />,
      },
      {
        exact: true,
        path: "/app/projects/overview",
        component: lazy(() => import("src/views/project/OverviewView")),
      },
      {
        exact: true,
        path: "/app/projects/browse",
        component: lazy(() => import("src/views/project/ProjectBrowseView")),
      },
      {
        exact: true,
        path: "/app/projects/create",
        component: lazy(() => import("src/views/project/ProjectCreateView")),
      },
      {
        exact: true,
        path: "/app/projects/:id",
        component: lazy(() => import("src/views/project/ProjectDetailsView")),
      },
      {
        exact: true,
        path: "/app/projects",
        component: () => <Redirect to="/app/projects/browse" />,
      },
      {
        exact: true,
        path: "/app/reports/dashboard",
        component: lazy(() =>
          import("src/views/reports/DashboardAlternativeView")
        ),
      },
      {
        exact: true,
        path: "/app/social/feed",
        component: lazy(() => import("src/views/social/FeedView")),
      },
      {
        exact: true,
        path: "/app/social/profile",
        component: lazy(() => import("src/views/social/ProfileView")),
      },
      {
        exact: true,
        path: "/app/social",
        component: () => <Redirect to="/app/social/profile" />,
      },
      {
        exact: true,
        path: "/app",
        component: () => <Redirect to="/app/reports/dashboard" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: "*",
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/home",
        component: HomeView,
      },
      {
        exact: true,
        path: "/home",
        component: HomeView,
      },
      {
        exact: true,
        path: "/pricing",
        component: lazy(() => import("src/views/pricing/PricingView")),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
