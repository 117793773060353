import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./footer.css";

const Footer = (props) => {
  const { t } = useTranslation("home-footer");

  return (
    <>
      <section className="footer">
        <Container>
          <Row>
            <Col md={12}>
              <div className="footer-logo">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/static/images/home/HolaChat-Black.png"
                  }
                  alt="logo"
                />
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="footer-widget">
                <h4>{t("product_section.title")}</h4>
                <ul>
                  <li>
                    <a href="/pricing">{t("product_section.pricing")}</a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col md={3} xs={6}>
              <div className="footer-widget">
                <h4>{t("about_section.title")}</h4>
                <ul>
                  <li>
                    <a href="/#">{t("about_section.tnc")}</a>
                  </li>
                  <li>
                    <a href="/#">{t("about_section.privacy")}</a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col md={3} xs={6}>
              <div className="footer-widget">
                <h4>{t("resource_section.title")}</h4>
                <ul>
                  <li>
                    <a href="/#">{t("resource_section.faqs")}</a>
                  </li>
                  <li>
                    <a href="/#">{t("resource_section.customer_stories")}</a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col md={3} xs={6}>
              <div className="footer-widget">
                <h4>{t("social_section.title")}</h4>
                <ol className="footer-social">
                  <li>
                    <a href="/#" className="icon">
                      <span className="fa fa-facebook-f"></span>
                    </a>
                  </li>
                  <li>
                    <a href="/#" className="icon">
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a href="/#" className="icon">
                      <span className="fa fa-linkedin"></span>
                    </a>
                  </li>
                </ol>

                <p>{t("social_section.have_questions")}</p>
                <p>
                  {t("social_section.contact")}{" "}
                  <a href="mailto:hello@holachat.io">hello@holachat.io</a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
