import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Button,
  Hidden,
  TextField,
  MenuItem,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import Logo from "src/components/Logo";
import { THEMES } from "src/constants";
import Account from "./Account";
// import Notifications from "./Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: "none",
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
  select: {
    marginRight: theme.spacing(2),

    "& .MuiSelect-root": {
      color: "#fff",
    },
    "& .MuiSelect-icon": {
      fill: "#fff",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t, i18n } = useTranslation("common");

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/app">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {/* <Search />
        <Contacts /> */}
        {/* <Notifications /> */}
        <TextField
          className={classes.select}
          value={i18n.language}
          onChange={(e) => i18n.changeLanguage(e.target.value)}
          size="small"
          variant="outlined"
          select
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="es">Español</MenuItem>
        </TextField>
        <Button
          color="info"
          variant="contained"
          onClick={() => history.push("/app/account/subscription")}
        >
          {t("dashboard_header.upgrade")}
        </Button>
        {/* <Settings /> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
