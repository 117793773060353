export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;
export const HOST = "https://api.holachat.io"
//export const HOST = "http://localhost:3000"
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};
