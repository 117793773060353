import React, {  useState, useEffect } from "react";
import { Container, Navbar, NavDropdown, Nav } from "react-bootstrap";

import { useTranslation } from "react-i18next";
// import LanguageIcon from "@material-ui/icons/Language";
import "./header.css";

const Header = () => {
  const { t, i18n } = useTranslation("home-header");
  const [language, setLanguage] = useState("English");

  const onLanguageClick = (language) => {
    if (language === "es") {
      i18n.changeLanguage("es");
      setLanguage("Español");
    } else {
      i18n.changeLanguage("en");
      setLanguage("English");
    }
  };

  useEffect(() => {
    if (i18n.language === "es") {
      setLanguage("Español");
    } else {
      setLanguage("English");
    }
  }, [i18n]);

  return (
    <div className="header">
      <Container fluid>
        <Navbar variant="dark" expand="lg">
          <Navbar.Brand href="/">
            <img
              src={process.env.PUBLIC_URL + "/static/images/home/HolaChat.png"}
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto ">
              {/* <Nav.Link href="/">Pay</Nav.Link>
                    <Nav.Link href="#link">Get Paid</Nav.Link>
                    <Nav.Link href="#link">Accountants</Nav.Link> */}
              <NavDropdown title={t("dropdown.title")} id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">
                  {t("dropdown.item_1")}
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  {t("dropdown.item_2")}
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  {t("dropdown.item_3")}
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">
                  {t("dropdown.item_4")}
                </NavDropdown.Item>
                <NavDropdown.Divider />
              </NavDropdown>
              <Nav.Link href="#link">{t("qna_button")}</Nav.Link>
              <Nav.Link href="/pricing">{t("pricing_button")}</Nav.Link>
              <NavDropdown title={language}>
                <NavDropdown.Item onClick={() => onLanguageClick("en")}>
                  English
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => onLanguageClick("es")}>
                  Español
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <a className="header-button" href="/login">
              {t("login_button")}
            </a>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default Header;
