import React from "react";
import Header from "src/layouts/HomeLayout/header.js";
import { Row, Container, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Footer from "src/layouts/HomeLayout/footer.js";
import "./home.css";

const HomeView = (props) => {
  const { t } = useTranslation("home-page");
  return (
    <React.Fragment>
      {/* Header Section */}
      <Header />
      {/* Header Section End*/}

      {/* Hero Section */}
      <section className="home-hero">
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <h1>{t("hero_section.title")}</h1>
              <p className="content">{t("hero_section.description")}</p>
              <p>
                <a href="/register" className="green-button">
                  {t("hero_section.cta_button")}
                </a>
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={12}>
              <p className="hero_text_image">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/static/images/home/security-pci.png"
                  }
                  alt={"text"}
                />
              </p>
              <p className="desktop_image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/static/images/home/holachat.gif"
                  }
                  alt={"hero"}
                />
              </p>
              <p className="mobile_image">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/static/images/home/holachat-mobile-hero.png"
                  }
                  alt={"hero"}
                />
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Hero Section End*/}

      {/* Section cards*/}
      <section className="cards">
        <Container>
          <img
            src={process.env.PUBLIC_URL + "/static/images/home/cards.jpg"}
            alt={"cards"}
          />
        </Container>
      </section>
      {/* Section cards End*/}

      {/* Section How It Works*/}
      <section className="white-section">
        <Container>
          <Row>
            <Col md={12} className="mb-5">
              <h2 className="title text-center">
                {t("how_it_works_section.title")}
              </h2>
            </Col>
            <Col md={4}>
              <div className="info-box">
                <p>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/images/home/formulario.svg"
                    }
                    alt={"icon"}
                  />
                </p>
                <h3>{t("how_it_works_section.item_1.title")}</h3>
                <p>{t("how_it_works_section.item_1.description")}</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="info-box">
                <p>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/images/home/holachat-embeded.svg"
                    }
                    alt={"icon"}
                  />
                </p>
                <h3>{t("how_it_works_section.item_2.title")}</h3>
                <p>{t("how_it_works_section.item_2.description")}</p>
              </div>
            </Col>

            <Col md={4}>
              <div className="info-box">
                <p>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/images/home/chatbot-answer-mano.svg"
                    }
                    alt={"icon"}
                  />
                </p>
                <h3>{t("how_it_works_section.item_3.title")}</h3>
                <p>{t("how_it_works_section.item_3.description")}</p>
              </div>
            </Col>

            <Col md={12} className="mt-5">
              <p className="text-center">
                <a className="purple-button" href="/register">
                  {t("how_it_works_section.cta_button")}
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section How It Work Ends*/}

      {/*Repeat Section*/}
      <section className="white-section">
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="mobile_image">
              <p className="text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/static/images/home/chatbot-citas-de-servicio.svg"
                  }
                  alt={"icon"}
                  className="new-feature-image"
                />
              </p>
            </Col>
            <Col md={6}>
              <h2 className="title">{t("feature_section.feature_1.title")}</h2>
              <p>{t("feature_section.feature_1.description")}</p>
            </Col>
            <Col md={6} className="desktop_image">
              <p className="text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/static/images/home/chatbot-citas-de-servicio.svg"
                  }
                  alt={"icon"}
                  className="new-feature-image"
                />
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/*Repeat Section end*/}

      {/*Repeat Section*/}
      <section className="white-section">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <p className="text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/static/images/home/Group-65-p-800.png"
                  }
                  alt={"icon"}
                  className="new-feature-image"
                />
              </p>
            </Col>

            <Col md={6}>
              <h2 className="title">{t("feature_section.feature_2.title")}</h2>
              <p>{t("feature_section.feature_2.description")}</p>
            </Col>
          </Row>
        </Container>
      </section>
      {/*Repeat Section end*/}
      {/*Repeat Section*/}
      <section className="white-section">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <p className="text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/static/images/home/chatbot-integration-whatsapp-google.png"
                  }
                  alt={"icon"}
                  className="new-feature-image"
                />
              </p>
            </Col>

            <Col md={6}>
              <h2 className="title">{t("feature_section.feature_3.title")}</h2>
              <p>{t("feature_section.feature_3.description")}</p>
            </Col>

            <Col md={12} className="mt-5">
              <p className="text-center">
                <a className="purple-button" href="/register">
                  {t("feature_section.cta_button")}
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/*Repeat Section end*/}

      {/*Repeat Section*/}
      <section className="white-section">
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <h2 className="title text-center mb-3">
                {t("testimonial_section.title")}
              </h2>
              <p className="text-center">
                <a href="#/" className="text-button">
                  {t("testimonial_section.case_studies")}
                </a>
              </p>
            </Col>

            <Col md={10}>
              <Row no-gutters="true">
                <Col lg={6}>
                  <div className="overflow_right">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/static/images/home/Group.min.png"
                      }
                      alt={"icon"}
                    />
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="overflow_left">
                    <div className="testibox">
                      <div className="testi_icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/static/images/home/CombinedShape.svg"
                          }
                          alt={"icon"}
                        />
                      </div>
                      <p className="text">
                        “Por fin puedes aplicar todo el poder de la tecnología
                        de AI y marketing sin ser un experto y obtener los
                        resultados como si lo fueras. Todo esto se logra con un
                        ChatBot de HolaChat”
                      </p>

                      <div className="author_data">
                        <div className="author_image">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/static/images/home/profile-image.png"
                            }
                            alt={"user"}
                          />
                        </div>
                        <div className="author_content">
                          <h4>Pedro Celada</h4>
                          <p>Fundador y Director General de Eventos</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/*Repeat Section end*/}

      {/*Repeat Section*/}
      <section className="white-section">
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <p className="text-center">
                <img
                  src={process.env.PUBLIC_URL + "/static/images/home/trust.jpg"}
                  alt={"user"}
                />
              </p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col md={4}>
              <div className="reviews">
                <p>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/static/images/home/stars.svg"
                    }
                    alt={"user"}
                  />
                </p>
                <div className="content_area">
                  <p>
                    ״Personaliza cualquier proceso en su totalidad, HolaChat te
                    da la facilidad de escoger las preguntas y respuestas más
                    frecuentes de mi negocio sin necesidad de que tenga ningún
                    conocimiento en programación o diseño, además es una
                    tecnología muy intuitiva. Muy bien hecho!״
                  </p>
                </div>

                <div className="author_data">
                  <div className="author_image">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/static/images/home/Miguel_Galeana.jpeg"
                      }
                      alt={"user"}
                      className="author_image"
                    />
                  </div>
                  <div className="author_content">
                    <h4>Miguel Galeana</h4>
                    <p>‍Dentista y Consultorio Familiar</p>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="reviews">
                <p>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/static/images/home/stars.svg"
                    }
                    alt={"user"}
                    
                  />
                </p>
                <div className="content_area">
                  <p>
                    ״HolaChat te permite crear secuencias de contenido con
                    preguntas frecuentes para filtrar a tu audiencia, el gestor
                    permite conectar con tus clientes usando Whatsapp. De una
                    manera fácil y sencilla.״
                  </p>
                </div>

                <div className="author_data">
                  <div className="author_image">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/static/images/home/Roberto_Gutierrez.jpeg"
                      }
                      alt={"user"}
                      className="author_image"
                    />
                  </div>
                  <div className="author_content">
                    <h4>Roberto Gutierrez</h4>
                    <p>Comercializadora Internalcional de México</p>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="reviews">
                <p>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/static/images/home/stars.svg"
                    }
                    alt={"user"}
               
                  />
                </p>
                <div className="content_area">
                  <p>
                    ״HolaChat está diseñado para ventas y marketing. Yo vendo
                    productos, reserve citas, desarrollo clientes potenciales, y
                    manejo de cotizaciones e información de contacto. Con
                    HolaChat ya puedo filtrar los clientes interesados!״
                  </p>
                </div>

                <div className="author_data">
                  <div className="author_image">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/static/images/home/Karen_Gomez.jpeg"
                      }
                      alt={"user"}
                      className="author_image"
                    />
                  </div>
                  <div className="author_content">
                    <h4>Karina Gomez</h4>
                    <p>‍Directora de Mercadotecnia de Mexprint</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col md={12}>
              <h2 className="title text-center mb-4">
                {t("cta_section.title")}
              </h2>
              <p className="text-center">
                <a className="purple-button" href="/register">
                  {t("cta_section.cta_button")}
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/*Repeat Section end*/}

      {/*Logo Section */}
      <section className="logos">
        <Container>
          <Row>
            <Col md={12}>
              <p className="text-center title">{t("mention")}</p>
            </Col>
          </Row>

          <Row>
            <Col>
              {" "}
              <img
                src={process.env.PUBLIC_URL + "/static/images/home/forbes.svg"}
                alt={"logo"}
                className="press-logo"
              />
            </Col>
            <Col>
              {" "}
              <img
                src={process.env.PUBLIC_URL + "/static/images/home/wsg.svg"}
                alt={"logo"}
                className="press-logo"
              />
            </Col>
            <Col>
              {" "}
              <img
                src={process.env.PUBLIC_URL + "/static/images/home/boom.svg"}
                alt={"logo"}
                className="press-logo"
              />
            </Col>
            <Col>
              {" "}
              <img
                src={process.env.PUBLIC_URL + "/static/images/home/re.svg"}
                alt={"logo"}
                className="press-logo"
              />
            </Col>
            <Col>
              {" "}
              <img
                src={
                  process.env.PUBLIC_URL + "/static/images/home/business.svg"
                }
                alt={"logo"}
                className="press-logo"
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/*Logo Section end*/}

      {/* Footer Section  */}
      <Footer />
      {/* Footer Section End */}
    </React.Fragment>
  );
};

export default HomeView;
